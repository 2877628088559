@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-groceries-how-it-works {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 50px 0 25px;
  width: 100%;

  &__title {
    color: $text-color-super-darken;
    font-size: rem(20);
    margin: 0;

    @media (min-width: 768px) {
      font-weight: 500;
      font-size: rem(36);
    }
  }

  &__description {
    color: $text-color-super-darken;
    font-weight: 300;
    font-size: rem(14);
    width: 90%;
    margin: 8px 0 30px;

    @media (min-width: 768px) {
      font-size: rem(20);
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      flex-direction: row;
      margin: 30px 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    justify-content: center;

    &:first-child {
      .marmita-lottie {
        width: 330px;
      }
    }

    .marmita-lottie {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 305px;
      width: 240px;

      @media (min-width: 768px) {
        max-width: 25vw;
      }
    }

    &-title {
      font-weight: 500;
      color: $text-color-super-darken;
      font-size: rem(16);
      width: 65%;
      margin: 25px 0 15px;

      @media (min-width: 768px) {
        font-size: rem(24);
      }
    }

    &-description {
      font-weight: 300;
      color: $text-color;
      font-size: rem(14);
      width: 90%;
      margin: 0;

      @media (min-width: 768px) {
        font-size: rem(18);
        width: 65%;
      }
    }
  }
}
