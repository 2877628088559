@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-groceries-faq {
  background-color: $light-grey-color-100;
  background-image: bucket-url('landing/groceries-background-banner-mobile.png', 't_banner');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  padding: 35px 0 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;

  @media (min-width: 768px) {
    background-image: url('/static/images/groceries-landing-page/desktop-faq-background.png');
    padding: 35px 0 280px;
  }

  &__title {
    font-size: rem(20);
    text-align: center;
    margin: 15px 0 45px;
    color: $text-color-super-darken;

    @media (min-width: 768px) {
      font-weight: 500;
      font-size: rem(36);
      margin: 50px 0 45px;
    }
  }

  &__list {
    width: 85%;
    max-width: 600px;
  }

  &__item {
    width: 100%;
    margin-bottom: 15px;
  }
}
