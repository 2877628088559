@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-groceries-famous-markets {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin: 70px 0 100px;
  }

  &__title {
    text-align: center;
    font-weight: normal;
    color: $text-color-super-darken;
    font-size: rem(20);

    @media (min-width: 768px) {
      font-weight: 500;
      font-size: rem(24);
    }
  }

  &__carousel {
    width: 90%;
    margin: 20px 0;
    max-width: 1085px;

    .carousel__controller {
      border: none;
      background-color: transparent !important;

      &--left {
        left: -22px;
        transform: translateY(-50%) rotateZ(90deg);
      }

      &--right {
        right: -22px;
        transform: translateY(-50%) rotateZ(-90deg);
      }
    }
  }

  &__list {
    display: flex;
    margin: 25px 0 0;
    width: 80%;
    align-items: center;
    justify-content: center;
    max-width: 1240px;
  }

  &__market {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-name {
      font-size: rem(12);
      line-height: 16px;
      color: $text-color-lighten;
      text-align: center;
      font-weight: normal;
    }
  }

  &__image-wrapper {
    margin: 0;
    width: 100%;
    height: auto;

    @media (min-width: 768px) {
      width: 84px;
    }
  }

  &__image {
    width: 100%;
  }
}
