@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.landing-groceries-faq-accordion {
  width: 100%;
  height: auto;
  text-align: left;

  &__header {
    background: none;
    border: none;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 0;
    border-bottom: 1px solid $light-grey-color-300;
    text-align: left;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &-title {
      font-size: rem(14);
      color: $text-color-super-darken;
      margin: 0;

      @media (min-width: 768px) {
        font-weight: 500;
        font-size: rem(18);
      }
    }

    &-arrow {
      transition: transform 0.5s;

      svg {
        width: 30px;
        color: $primary-color;
      }

      &--opened {
        transform: rotate(-180deg) translateY(5px);
      }
    }
  }

  &__description {
    color: $text-color;
    line-height: 130%;
    max-height: 0;
    overflow: hidden;
    font-size: rem(14);
    margin: 0;
    font-weight: 300;
    transition: max-height 0.2s, margin 0.2s;

    @media (min-width: 768px) {
      font-size: rem(18);
    }

    &--toggled {
      max-height: 100vh;
      margin-top: 10px;

      @media (min-width: 768px) {
        margin-top: 15px;
      }
    }
  }
}
