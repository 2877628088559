@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.groceries-landing-page {
  display: flex;
  flex-direction: column;
  background-image: bucket-url('landing/groceries-background-mobile.png', 't_banner');
  background-repeat: no-repeat;
  background-position: center -15px;
  background-size: 100% auto;

  @media (min-width: 768px) {
    background-size: 125% auto;
    background-position: center 20px;
    background-image: url('/static/images/groceries-landing-page/desktop-background.webp');
  }

  @media (min-width: 900px) {
    background-position: center -15px;
    background-size: 100% auto;
  }

  &__area {
    display: flex;
    width: 100%;
    height: auto;
  }

  &__unavailable {
    padding: 0 15px;
    text-align: center;

    @media (min-width: 768px) {
      padding: 45px 60px;

      &-text {
        font-size: 1.8em;
      }
    }
  }
}
